import get from 'lodash/get';
import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';
import * as auth from '../../utils/auth';

export default (type, params) => {
  switch (type) {
    case AUTH_LOGIN:
      return login(params);

    case AUTH_LOGOUT:
      return logout();

    case AUTH_ERROR:
      return error(params);

    case AUTH_CHECK:
      return check();

    case AUTH_GET_PERMISSIONS:
      let token = localStorage.getItem('token');
      let permissions = auth.hasAdminPermissions(token);

      return permissions ? Promise.resolve(permissions) : Promise.reject();

    default:
      return Promise.resolve();
  }
};

const login = async ({ username, password }) => {
  let loginRes = await auth.login({ email: username, password });
  let token = get(loginRes, 'data.token', null);

  let permissions = auth.hasAdminPermissions(token);
  if (permissions === false) {
    return Promise.reject('No tienes permisos de administración.');
  }

  localStorage.setItem('token', token);

  return Promise.resolve();
};

const logout = () => {
  localStorage.clear();

  return Promise.resolve();
};

const error = params => {
  const status = get(params, 'response.status', -1);

  if (status === 401 || status === 403) {
    localStorage.clear();
    return Promise.reject();
  }

  return Promise.resolve();
};

const check = () => {
  return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
};
