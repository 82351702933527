import React from 'react';
import { Datagrid, TextField, EditButton, List } from 'react-admin';

export default (props) => (
  <List {...props} exporter={false}>
    <Datagrid>
      <TextField source='enrollment' />
      <TextField source='name' />
      <EditButton />
    </Datagrid>
  </List>
);
