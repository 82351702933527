import { PropTypes } from 'prop-types';
import React from 'react';
import { required, SimpleForm, TextInput } from 'react-admin';

const ClientsForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source='enrollment' validate={required()} fullWidth />
    <TextInput source='name' validate={required()} fullWidth />
  </SimpleForm>
);

ClientsForm.propTypes = {
  record: PropTypes.object,
  type: PropTypes.oneOf(['create', 'edit']),
};

ClientsForm.defaultProps = {
  type: 'create',
};

export default ClientsForm;
