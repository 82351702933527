import React from 'react';
import { Datagrid, EditButton, List, ReferenceField, TextField } from 'react-admin';
import Filters from './filters';

export default props => (
  <List {...props} filters={<Filters />} exporter={false}>
    <Datagrid>
      <TextField source='code' />
      <ReferenceField source='warehouse' reference='warehouse_super_classes' linkType={false} allowEmpty>
        <TextField source='name' />
      </ReferenceField>
      <ReferenceField source='model' reference='battery_models' allowEmpty>
        <TextField source='name' />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);
