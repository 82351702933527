import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { AppBar } from 'react-admin';
import styles from './styles';
import {importBatteries} from '../../../providers/importProvider';

export default withStyles(styles)(({ classes, ...props }) => (
  <AppBar {...props}>
    <Typography variant='title' color='inherit' className={classes.title}>
      Zenteo - <span id='react-admin-title'></span>
    </Typography>
      <button
          id={"buttonImportBatteries"}
          onClick={importBatteries}
          style={{
              backgroundColor: "transparent",
              border: "1px solid #ffffff",
              color: "#ffffff",
              fontSize: "16px",
              cursor: "pointer",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
      >
          Importar baterías
      </button>
    <span className={classes.spacer} />
  </AppBar>
));
