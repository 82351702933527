import { PropTypes } from 'prop-types';
import React from 'react';
import { required, SimpleForm, TextInput, ReferenceArrayInput, SelectArrayInput } from 'react-admin';

const OperatorsForm = (props) => {
  const common = {
    fullWidth: true,
  };

  return (
    <SimpleForm {...props}>
      <TextInput source='name' validate={required()} {...common} />
      <ReferenceArrayInput source='warehouse' reference='warehouses' {...common}>
        <SelectArrayInput optionText='name' />
      </ReferenceArrayInput>
    </SimpleForm>
  );
};

OperatorsForm.propTypes = {
  record: PropTypes.object,
  type: PropTypes.oneOf(['create', 'edit']),
};

OperatorsForm.defaultProps = {
  type: 'create',
};

export default OperatorsForm;
