import React from 'react';
import { Edit } from 'react-admin';
import Form from '../../../molecules/Config/Form';

const Title = ({ record }) => <span>Val</span>;

export default (props) => (
    <Edit {...props} title={<Title />} actions={null}>
        <Form type='edit' redirect='list' />
    </Edit>
);

