import React from 'react';

import {importOpts1, importOpts2, importOpts3, importOpts4} from "../../../../providers/importProvider";
import {
    Star as StarIcon,
    StarBorder as StarBorderIcon
} from '@material-ui/icons';

class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opt2: 0,
            opt2cifra: 0,
            opt1: 0,
            opt1cifra: 0,
            opt3: 0,
            opt3cifra: 0,
            opt4: 0,
            opt4cifra: 0
        };
    }

    async componentDidMount() {
        const elements = ['1', '2', '3', '4', '5'];

        await importOpts2()
            .then((res) => {
                if(res){

                    const items = []
                    for (const value of elements.entries()) {
                        if(value[1] <= res.data){
                            items.push(<StarIcon></StarIcon>)
                        }else{
                            items.push(<StarBorderIcon></StarBorderIcon>)
                        }
                    }
                    var val = 0;
                    if(res.data){
                        val = res.data.toFixed(2)
                    }
                    this.setState(() => ({
                        opt2: items,
                        opt2cifra: val
                    }))
                }
            });
        await importOpts1()
            .then((res) => {
                if(res){
                    const items = []
                    for (const value of elements.entries()) {
                        if(value[1] <= res.data){
                            items.push(<StarIcon></StarIcon>)
                        }else{
                            items.push(<StarBorderIcon></StarBorderIcon>)
                        }
                    }
                    var val = 0;
                    if(res.data){
                        val = res.data.toFixed(2)
                    }
                    this.setState(() => ({
                        opt1: items,
                        opt1cifra: val
                    }))
                }
            });
        await importOpts3()
            .then((res) => {
                if(res){
                    const items = []
                    for (const value of elements.entries()) {
                        if(value[1] <= res.data){
                            items.push(<StarIcon></StarIcon>)
                        }else{
                            items.push(<StarBorderIcon></StarBorderIcon>)
                        }
                    }
                    var val = 0;
                    if(res.data){
                        val = res.data.toFixed(2)
                    }
                    this.setState(() => ({
                        opt3: items,
                        opt3cifra: val
                    }))
                }
            });
        await importOpts4()
            .then((res) => {
                if(res){
                    const items = []
                    for (const value of elements.entries()) {
                        if(value[1] <= res.data){
                            items.push(<StarIcon></StarIcon>)
                        }else{
                            items.push(<StarBorderIcon></StarBorderIcon>)
                        }
                    }
                    var val = 0;
                    if(res.data){
                        val = res.data.toFixed(2)
                    }
                    this.setState(() => ({
                        opt4: items,
                        opt4cifra: val
                    }))
                }
            });
    }


    render() {

        return (
            <div>
                <h1>Media de las puntuaciones de los clientes</h1>

                <h2>Montaje de batería en domicilio</h2>
                <div>{this.state.opt1}</div>
                <div>{this.state.opt1cifra} puntos</div>
                <h2>Montaje de batería en taller</h2>
                <div>{this.state.opt2}</div>
                <div>{this.state.opt2cifra} puntos</div>
                <h2>Envío a de batería a domicilio</h2>
                <div>{this.state.opt3}</div>
                <div>{this.state.opt3cifra} puntos</div>
                <h2>Recogida en tienda</h2>
                <div>{this.state.opt4}</div>
                <div>{this.state.opt4cifra} puntos</div>

            </div>
        );
    }
}
export default List;
