import { PropTypes } from 'prop-types';
import React from 'react';
import { Datagrid, EditButton, ReferenceManyField, required, SimpleForm, TextInput } from 'react-admin';
import * as validation from '../../../../utils/validation';
import IdentifierField from '../../../atoms/IdentifierField';

const ClientsForm = props => (
  <SimpleForm {...props}>
    <TextInput source='name' validate={required()} fullWidth />
    <TextInput source='surname' validate={required()} fullWidth />
    <TextInput source='phone' validate={[required(), validation.telephoneNumber]} fullWidth />
    <TextInput source='email' validate={validation.requiredEmail} fullWidth />

    {props.type === 'edit' && (
      <ReferenceManyField reference='services' target='client' label='Servicios'>
        <Datagrid>
          <IdentifierField source='id' />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    )}
  </SimpleForm>
);

ClientsForm.propTypes = {
  record: PropTypes.object,
  type: PropTypes.oneOf(['create', 'edit']),
};

ClientsForm.defaultProps = {
  type: 'create',
};

export default ClientsForm;
