import React from 'react';
import { Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import * as renderers from '../../../../utils/renderers';

export default (props) => (
  <Filter {...props}>
    <TextInput source='name' />
    <TextInput source='surname' />
    <TextInput source='email' />
    <TextInput source='phone' />
    <ReferenceInput source='currentService' reference='services' perPage={999}>
      <SelectInput optionText={renderers.Identifier} />
    </ReferenceInput>
    <ReferenceInput source='status' reference='operator_statuses' perPage={999}>
      <SelectInput optionText='name' />
    </ReferenceInput>
  </Filter>
);
