import { PropTypes } from 'prop-types';
import React from 'react';
import { ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin';
import * as renderers from '../../../../utils/renderers';
import * as validation from '../../../../utils/validation';
import MapField from '../../../atoms/MapField';

const OperatorsForm = (props) => {
  const common = {
    validate: [required()],
    fullWidth: true,
  };

  return (
    <SimpleForm {...props}>
      <TextInput source='name' {...common} />
      <TextInput source='surname' {...common} />
      <ReferenceInput perPage={999} source='status' reference='operator_statuses' fullWidth>
        <SelectInput optionText={'name'} />
      </ReferenceInput>
      <ReferenceInput perPage={999} source='currentService' reference='services' allowEmpty fullWidth>
        <SelectInput optionText={renderers.Identifier} />
      </ReferenceInput>
      <ReferenceInput perPage={999} source='warehouse' reference='warehouses' fullWidth>
        <SelectInput optionText={'name'} />
      </ReferenceInput>
      <ReferenceInput perPage={999} source='car' reference='cars' {...common}>
        <SelectInput optionText={'name'} />
      </ReferenceInput>
      <TextInput source='phone' validate={[required(), validation.telephoneNumber]} fullWidth />
      <TextInput source='email' validate={validation.requiredEmail} fullWidth />
      <TextInput
        source='password'
        validate={props.type === 'edit' ? validation.optionalPassword(6) : validation.requiredPassword}
        fullWidth
      />

      {props.type === 'edit' && <MapField sources={['latitude', 'longitude']} label='Localización' />}
    </SimpleForm>
  );
};

OperatorsForm.propTypes = {
  record: PropTypes.object,
  type: PropTypes.oneOf(['create', 'edit']),
};

OperatorsForm.defaultProps = {
  type: 'create',
};

export default OperatorsForm;
