import React from 'react';
import {
  Datagrid,
  EditButton,
  List,
  ShowButton,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  withTranslate,
} from 'react-admin';

export default withTranslate(({ translate, ...props }) => (
  <List {...props} exporter={false}>
    <Datagrid>
      <TextField source='name' />
      <ReferenceArrayField reference='warehouses' source='warehouse'>
        <SingleFieldList>
          <ChipField source='name' />
        </SingleFieldList>
      </ReferenceArrayField>
      <ShowButton label={translate('resources.calendars.fields.daysWithoutSchedule')} />
      <EditButton />
    </Datagrid>
  </List>
));
