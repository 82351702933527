import enMessages from 'ra-language-english';
import esMessages from 'ra-language-spanish';
import domainMessages from '../../assets/i18n';

const raMessages = {
  es: esMessages,
  en: enMessages,
};

const messages = {
  es: { ...raMessages.es, ...domainMessages.es },
  en: { ...raMessages.en, ...domainMessages.en },
};

const i18nProvider = locale => messages[locale];

export default i18nProvider;
