import { PropTypes } from 'prop-types';
import React from 'react';
import {NumberInput, required, SimpleForm} from 'react-admin';

const OperatorsForm = (props) => {
  const common = {
    validate: [required()],
    fullWidth: true,
  };

  return (
    <SimpleForm {...props}>
      <NumberInput min={1} source='value' {...common} />
    </SimpleForm>
  );
};

OperatorsForm.propTypes = {
  record: PropTypes.object,
  type: PropTypes.oneOf(['edit']),
};

OperatorsForm.defaultProps = {
  type: 'create',
};

export default OperatorsForm;
