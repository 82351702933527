import PropTypes from 'prop-types';
import React from 'react';
import * as renderers from '../../../utils/renderers';

const IdentifierField = ({ record = {}, source }) => {
  return <span>{renderers.Identifier(record)}</span>;
};

IdentifierField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default IdentifierField;
