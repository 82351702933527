import React from 'react';
import { Datagrid, EditButton, List, TextField } from 'react-admin';
import ListActions from '../../../molecules/ListActions';

export default (props) => (
    <List {...props} actions={<ListActions showCreateButton={true} />} exporter={false}>
        <Datagrid>
            <TextField source='name' />
            <TextField source='value' />
            <EditButton />
        </Datagrid>
    </List>
);
