import React from 'react';
import { DateInput, Filter, NumberInput, ReferenceInput, SelectInput } from 'react-admin';
import * as renderers from '../../../../utils/renderers';

export default (props) => (
  <Filter {...props}>
    <DateInput source='date' />
    <NumberInput source='id' />
    <ReferenceInput source='operator' reference='operators' perPage={999}>
      <SelectInput optionText='name' />
    </ReferenceInput>
    <ReferenceInput source='replacement' reference='batteries' perPage={999}>
      <SelectInput optionText={renderers.BatteryCodeModel} />
    </ReferenceInput>
    <ReferenceInput source='status' reference='service_statuses' perPage={999}>
      <SelectInput optionText='name' />
    </ReferenceInput>
  </Filter>
);
