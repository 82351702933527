import { PropTypes } from 'prop-types';
import React from 'react';
import {
  required,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';

const OperatorsForm = (props) => {
  const common = {
    validate: [required()],
    fullWidth: true,
  };

  return (
    <SimpleForm {...props}>
      <TextInput source='name' {...common} />
      <TextInput source='direction' {...common} />
      <NumberInput source='latitude' {...common} />
      <NumberInput source='longitude' {...common} />
      <TextInput source='locationRef' {...common} />
      <TextInput source='codeBcId' {...common} />
      <ReferenceInput source='calendar' reference='calendars' {...common}>
        <SelectInput optionText='name' />
      </ReferenceInput>
      <ReferenceArrayInput source='operators' reference='operators' {...common}>
        <SelectArrayInput optionText='name' />
      </ReferenceArrayInput>
    </SimpleForm>
  );
};

OperatorsForm.propTypes = {
  record: PropTypes.object,
  type: PropTypes.oneOf(['create', 'edit']),
};

OperatorsForm.defaultProps = {
  type: 'create',
};

export default OperatorsForm;
