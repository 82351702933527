import { PropTypes } from 'prop-types';
import React from 'react';
import {
  Datagrid,
  DateInput,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  NullableBooleanInput,
    TextField
} from 'react-admin';
import * as renderers from '../../../../utils/renderers';
import * as validation from '../../../../utils/validation';
import FileUrlField from '../../../atoms/FileUrlField';

const ServicesForm = (props) => {
  const common = {
    validate: [required()],
    fullWidth: true,
  };

  return (
    <SimpleForm {...props}>
      <DateInput source='dateDate' {...common} />
      <TextInput
        source='dateTime'
        format={(v) => (v && v.match(/^(\d+:\d+)/g)) || v}
        validate={[required(), validation.hour]}
        fullWidth
      />
      <ReferenceInput perPage={999} source='status' reference='service_statuses' {...common}>
        <SelectInput optionText='name' />
      </ReferenceInput>
      <ReferenceInput perPage={999} source='replacementModel' reference='battery_models' linkType={false} {...common}>
        <SelectInput optionText='name' />
      </ReferenceInput>
      <ReferenceInput
        perPage={999}
        source='replacement'
        reference='batteries'
        linkType={false}
        allowEmpty
        fullWidth='true'
      >
        <SelectInput optionText={renderers.BatteryCodeModel} />
      </ReferenceInput>
      <TextInput source='carModel' />
      <TextInput source='enrollment' />
      <TextField source='ktypeId' />
      <ReferenceInput perPage={999} source='operator' reference='operators' allowEmpty fullWidth='true'>
        <SelectInput optionText={renderers.Fullname} />
      </ReferenceInput>
      <ReferenceInput perPage={999} source='client' reference='unregistered_clients' {...common}>
        <SelectInput optionText={renderers.Fullname} />
      </ReferenceInput>
      <NullableBooleanInput source='isNeedChangeBattery' fullWidth='true' />
      <NullableBooleanInput source='isChangedBattery' fullWidth='true' />

      <NullableBooleanInput source='hasResidualDelivery' fullWidth='true' disabled />
      <TextInput source='weightResidue' fullWidth disabled />

      <TextInput source='reason' fullWidth='true' />
      <TextInput source='explainReason' multiline fullWidth='true' />
      <TextInput source='address' {...common} />
      <TextInput source='comments' multiline fullWidth />

      {props.type === 'edit' && (
        <ReferenceManyField reference='media_objects' target='files' label='Archivos'>
          <Datagrid>
            <FileUrlField source='contentUrl' />
          </Datagrid>
        </ReferenceManyField>
      )}
    </SimpleForm>
  );
};

ServicesForm.propTypes = {
  record: PropTypes.object,
  type: PropTypes.oneOf(['create', 'edit']),
};

ServicesForm.defaultProps = {
  type: 'create',
};

export default ServicesForm;
