import PropTypes from 'prop-types';
import React from 'react';
import * as renderers from '../../../utils/renderers';

const FullnameField = ({ record = {}, source }) => <span>{renderers.Fullname(record)}</span>;

FullnameField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default FullnameField;
