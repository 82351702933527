import React from 'react';
import { Datagrid, DateField, EditButton, List, ReferenceField, TextField, withTranslate } from 'react-admin';
import BatteryNameField from '../../../atoms/BatteryNameField';
import FullnameField from '../../../atoms/FullnameField';
import IdentifierField from '../../../atoms/IdentifierField';
import Filters from './filters';
import get from 'lodash/get';

const ServiceOrigin = ({ source, record = {}, translate }) => {
  const manual = get(record, 'manual', false);
  const landing = get(record, 'isLanding', false);
  const origin = manual
    ? translate('resources.services.fields.manual')
    : landing
    ? translate('resources.services.fields.landing')
    : translate('resources.services.fields.web');

  return <span>{origin}</span>;
};

ServiceOrigin.defaultProps = {
  addLabel: true,
};

const list = (props) => {
  const translate = props.translate;
  return (
    <List {...props} filters={<Filters />} exporter={false}>
      <Datagrid>
        <DateField source='date' showTime />
        <IdentifierField source='id' />
        <TextField source='ktypeId' />
        <ReferenceField source='operator' reference='operators' allowEmpty>
          <FullnameField source='name' />
        </ReferenceField>
        <ReferenceField source='replacement' reference='batteries' linkType={false} allowEmpty>
          <BatteryNameField source='code' />
        </ReferenceField>
        <ReferenceField source='replacementModel' reference='battery_models' linkType={false} allowEmpty>
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField source='status' reference='service_statuses' linkType={false} allowEmpty>
          <TextField source='name' />
        </ReferenceField>
        <ServiceOrigin translate={translate} label={translate('resources.services.fields.origin')} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default withTranslate(list);
