import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { get, has, isNumber } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Labeled } from 'react-admin';
import { GOOGLE_MAPS_API_KEY, MAP_DEFAULT_LOCATION } from '../../../config/constants';
import styles from './styles';

const MapField = ({ record = {}, sources, label, google }) => {
  const [latSource, lngSource] = sources;
  const showMarker = (has(record, latSource) && isNumber(get(record, latSource))) === true;
  let location = {};
  if (showMarker) {
    location = {
      lat: get(record, latSource, null),
      lng: get(record, lngSource, null),
    };
  } else {
    location = MAP_DEFAULT_LOCATION;
  }

  return (
    <Labeled label={label} fullWidth>
      <div style={styles.container}>
        <Map google={google} zoom={14} initialCenter={location}>
          {showMarker && <Marker />}
        </Map>
      </div>
    </Labeled>
  );
};

MapField.propTypes = {
  record: PropTypes.object,
  sources: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_API_KEY,
})(MapField);
