import React from 'react';
import { Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';

export default (props) => (
  <Filter {...props}>
    <TextInput source='code' />
    <ReferenceInput source='warehouse' reference='warehouse_super_classes' perPage={999}>
      <SelectInput optionText='name' />
    </ReferenceInput>
    <ReferenceInput source='model' reference='battery_models' perPage={999}>
      <SelectInput optionText='name' />
    </ReferenceInput>
  </Filter>
);
