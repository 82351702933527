import LaunchIcon from '@material-ui/icons/Launch';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { BASE_URL_PUBLIC } from '../../../config/constants';

const FileUrlField = ({ record = {}, source }) => {
  let name = null;
  const url = get(record, source, '');
  const path = url.split('/');
  if (path.length) name = path[path.length - 1];

  return (
    <a href={`${BASE_URL_PUBLIC}${url}`}>
      {name} <LaunchIcon />
    </a>
  );
};

FileUrlField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default FileUrlField;
