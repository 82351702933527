import * as React from 'react';
import { CardActions, ListButton } from 'react-admin';

const ShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} record={data} resource={resource} />
  </CardActions>
);

export default ShowActions;
