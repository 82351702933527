import { PropTypes } from 'prop-types';
import React from 'react';
import {
  Datagrid,
  DateField,
  minValue,
  number,
  NumberInput,
  ReferenceField,
  ReferenceManyField,
  required,
  SimpleForm,
  TextField,
  TextInput,
    BooleanInput
} from 'react-admin';
import FavoriteIcon from '@material-ui/icons/Favorite';

const OperatorsForm = props => {
  const common = {
    text: {
      validate: [required()],
      fullWidth: true,
    },
    number: {
      validate: [required(), number(), minValue(1)],
      fullWidth: true,
    },
  };

  return (
    <SimpleForm {...props}>
      <TextInput source='name' {...common.text} />
      <TextInput disabled source='stock' fullWidth="true" />
      <TextInput source='code' {...common.text} />
      <NumberInput source='price' {...common.number} />
      <NumberInput source='capacidad' {...common.number} />
      <NumberInput source='potencia' {...common.number} />
      <TextInput source='description' multiline />
        <BooleanInput
            source="excluirXmlGoogle"
            options={{
                checkedIcon: <FavoriteIcon />,
            }}
        />

      {props.type === 'edit' && (
        <ReferenceManyField reference='stock_logs' target='model' label='Registro del Almacén'>
          <Datagrid>
            <DateField source='date' showTime />
            <TextField source='log' />
            <ReferenceField source='userAction' reference='operators' linkType={false} allowEmpty>
              <TextField source='email' />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      )}
    </SimpleForm>
  );
};

OperatorsForm.propTypes = {
  record: PropTypes.object,
  type: PropTypes.oneOf(['create', 'edit']),
};

OperatorsForm.defaultProps = {
  type: 'create',
};

export default OperatorsForm;
