import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { fetchHeaders } from './headers';

export default entrypoint => {
  return parseHydraDocumentation(entrypoint, {
    headers: new Headers(fetchHeaders()),
  }).then(
    ({ api }) => ({ api }),
    result => {
      switch (result.status) {
        case 401:
          return Promise.resolve({
            api: result.api,
            customRoutes: [
              {
                props: {
                  path: '/',
                  render: () => <Redirect to={`/login`} />,
                },
              },
            ],
          });

        default:
          return Promise.reject(result);
      }
    }
  );
};
