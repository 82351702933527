import React from 'react';
import { Datagrid, EditButton, EmailField, List } from 'react-admin';
import FullnameField from '../../../atoms/FullnameField';
import TelephoneField from '../../../atoms/TelephoneField';
import Filters from './filters';

export default props => (
  <List {...props} filters={<Filters />} exporter={false}>
    <Datagrid>
      <FullnameField source='name' />
      <EmailField source='email' />
      <TelephoneField source='phone' />
      <EditButton />
    </Datagrid>
  </List>
);
