import PropTypes from 'prop-types';
import React from 'react';
import * as renderers from '../../../utils/renderers';

const BatteryNameField = ({ record = {}, source }) => <span>{renderers.BatteryCodeModel(record)}</span>;

BatteryNameField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default BatteryNameField;
