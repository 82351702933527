import React from 'react';
import {
  Datagrid,
  EditButton,
  List,
  DateField,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceField,
} from 'react-admin';
import ListActions from '../../../molecules/ListActions';
import Filters from './filters';

export default (props) => (
  <List {...props} filters={<Filters />} actions={<ListActions showCreateButton={true} />} exporter={false}>
    <Datagrid>
      <DateField source='date' />
      <TextField showTime source='timeFrom' />
      <TextField showTime source='timeTo' />
      <ReferenceArrayField reference='warehouses' source='warehouses'>
        <SingleFieldList>
          <ChipField source='name' />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField source='calendar' allowEmpty reference='calendars' linkType={false}>
        <TextField source='name' />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);
