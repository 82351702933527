import React from 'react';
import { Filter, TextInput } from 'react-admin';

export default props => (
  <Filter {...props}>
    <TextInput source='name' />
    <TextInput source='surname' />
    <TextInput source='email' />
    <TextInput source='phone' />
  </Filter>
);
