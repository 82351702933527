import React from 'react';
import { Datagrid, EditButton, List, TextField } from 'react-admin';
import Filters from './filters';
import ListActions from '../../../molecules/ListActions';

export default (props) => (
  <List {...props} filters={<Filters />} actions={<ListActions />} exporter={false}>
    <Datagrid>
      <TextField source='name' />
      <TextField source='stock' />
      <EditButton />
    </Datagrid>
  </List>
);
