import { HydraAdmin, ResourceGuesser } from '@api-platform/admin';
import {
  BatteryCharging90 as BatteryStockIcon,
  BatteryFull as BatteryModelsIcon,
  Build as OperatorsIcon,
  Group as ClientsIcon,
  ShoppingCart as ServicesIcon,
  DateRange as CalendarIcon,
  Business as WarehouseIcon,
  Today as TodayIcon,
  DirectionsCar as CarIcon,
  Star as StarIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import React from 'react';
import { Resource } from 'react-admin';
import * as config from '../../../config';
import * as providers from '../../../providers';
import apiDocumentationParser from '../../../providers/dataProvider/parser';
import * as pages from '../../pages';
import Layout from '../Layout';

export default (props) => {
  const hidden = [
    'service_statuses',
    'operator_statuses',
    'media_objects',
    'replacement',
    'stock_logs',
    'warehouse_super_classes',
  ];

  return (
    <HydraAdmin
      entrypoint={config.constants.ENTRY_POINT}
      i18nProvider={providers.i18nProvider}
      authProvider={providers.authProvider}
      dataProvider={providers.dataProvider}
      apiDocumentationParser={apiDocumentationParser}
      locale={'es'}
      theme={config.theme}
      loginPage={pages.Login}
      appLayout={Layout}
    >
      <ResourceGuesser {...pages.Services} name='services' icon={ServicesIcon} />
      <ResourceGuesser {...pages.Operators} name='operators' icon={OperatorsIcon} />
      <ResourceGuesser {...pages.Models} name='battery_models' icon={BatteryModelsIcon} />
      <ResourceGuesser {...pages.Batteries} name='batteries' icon={BatteryStockIcon} />
      <ResourceGuesser {...pages.Clients} name='unregistered_clients' icon={ClientsIcon} />
      <ResourceGuesser {...pages.Cars} name='cars' icon={CarIcon} />
      <Resource {...pages.Warehouses} name='warehouses' icon={WarehouseIcon} />
      <Resource {...pages.Calendars} name='calendars' icon={CalendarIcon} />
      <Resource {...pages.Schedules} name='schedules' icon={TodayIcon} />
      <Resource {...pages.Stars} name='star' icon={StarIcon} />
      <Resource {...pages.Configs} name='configs' icon={SettingsIcon} />

      {hidden.map((name) => (
        <Resource name={name} key={name} />
      ))}
    </HydraAdmin>
  );
};
