import get from 'lodash/get';
import React from 'react';
import { Edit } from 'react-admin';
import Form from '../../../molecules/Batteries/Form';

const Title = ({ record }) => <span>{get(record, 'code', '')}</span>;

export default (props) => (
  <Edit {...props} title={<Title />} actions={null}>
    <Form type='edit' redirect='list' />
  </Edit>
);
