import * as importApi from '../../utils/imports';

export const importBatteries = async () => {
    document.getElementById('buttonImportBatteries').style.display = 'none';
    importApi.importBatteries()
        .then( () => {
            document.getElementById('buttonImportBatteries').style.display = 'block';
            alert('Importación realizada con éxito.')
        })
        .catch(() => {
            document.getElementById('buttonImportBatteries').style.display = 'block';
            alert('Error al realizar la importación.')
        });
};

export const importOpts1 = async () => {
    return await importApi.importOpt1()
};

export const importOpts2 = async () => {
    return await importApi.importOpt2()
};

export const importOpts3 = async () => {
    return await importApi.importOpt3()
};

export const importOpts4 = async () => {
    return await importApi.importOpt4()
};
