import get from 'lodash/get';
import moment from 'moment';

export const Fullname = (record) => {
  const name = get(record, 'name', '');
  const surname = get(record, 'surname', '');
  return `${name} ${surname}`;
};

export const Schedule = (record) => {
  const date = record.date ? moment(record.date).format('DD/MM/YYYY') : '';
  return `${date}`;
};

export const WareHouse = (record) => {
  const name = get(record, 'name', '');
  return `${name}`;
};

export const Calendar = (record) => {
  const name = get(record, 'name', '');
  return `${name}`;
};

export const Identifier = (record) => {
  let identifier = null;
  const id = get(record, 'id', '');
  const explode = id.split('/');

  if (explode.length) identifier = parseInt(explode[explode.length - 1]);

  return `#${identifier}`;
};

export const BatteryCodeModel = (record) => {
  const code = get(record, 'code', '');
  const model = get(record, 'modelName', '');
  return `${code} - ${model}`;
};
