import React, { cloneElement } from 'react';
import { CardActions, CreateButton, ExportButton, sanitizeListRestProps } from 'react-admin';

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  showCreateButton,
  showExportButton,
  ...rest
}) => (
  <CardActions className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {showCreateButton && <CreateButton basePath={basePath} />}
    {showExportButton && (
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={maxResults}
      />
    )}
  </CardActions>
);

export default ListActions;
