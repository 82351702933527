import isEmpty from 'lodash/isEmpty';
import Polyglot from 'node-polyglot';
import { email, minLength, number, regex, required } from 'react-admin';
import i18nProvider from '../../providers/i18nProvider';

export const requiredEmail = [required(), email()];
export const requiredNumber = [required(), number()];
export const telephoneNumber = regex(/^(\+34|0034|34)?[6|7|8|9][0-9]{8}$/, 'Debe ser un número de teléfono');
export const hour = regex(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Debe ser una hora (hh:mm)');
export const requiredPassword = [required(), minLength(6)];
export const optionalPassword = (min, message = i18nProvider('es').ra.validation.minLength) => {
  message = Polyglot.transformPhrase(message, { min });

  return value => {
    return isEmpty(value) || (value && value.trim().length >= min) ? undefined : message;
  };
};
