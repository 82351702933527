import React from 'react';
import { Edit } from 'react-admin';
import * as renderers from '../../../../utils/renderers';
import Form from '../../../molecules/Schedules/Form';

const Title = ({ record }) => <span>{renderers.Schedule(record)}</span>;

export default (props) => (
  <Edit title={<Title />} {...props} actions={null}>
    <Form type='edit' />
  </Edit>
);
