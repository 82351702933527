import { dataProvider as baseDataProvider, fetchHydra as baseFetchHydra } from '@api-platform/admin';
import { ENTRY_POINT } from '../../config/constants';
import { fetchHeaders } from './headers';
import apiDocumentationParser from './parser';

const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders()),
  });

export default baseDataProvider(ENTRY_POINT, fetchHydra, apiDocumentationParser);
