import { PropTypes } from 'prop-types';
import React from 'react';
import {
  required,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  DateInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import * as validation from '../../../../utils/validation';

const OperatorsForm = (props) => {
  const common = {
    fullWidth: true,
  };

  return (
    <SimpleForm {...props}>
      <DateInput
        InputLabelProps={{
          shrink: true,
        }}
        source='date'
        validate={required()}
        {...common}
      />

      <TextInput
        InputLabelProps={{
          shrink: true,
        }}
        type='dateTime'
        format={(v) => (v && v.match(/^(\d+:\d+)/g)) || v}
        validate={[required(), validation.hour]}
        source='timeFrom'
        {...common}
      />
      <TextInput
        InputLabelProps={{
          shrink: true,
        }}
        type='dateTime'
        format={(v) => (v && v.match(/^(\d+:\d+)/g)) || v}
        validate={[required(), validation.hour]}
        source='timeTo'
        {...common}
      />
      <ReferenceInput source='calendar' reference='calendars' {...common}>
        <SelectInput optionText='name' />
      </ReferenceInput>
      <ReferenceArrayInput source='operators' reference='operators' {...common}>
        <SelectArrayInput optionText='name' />
      </ReferenceArrayInput>
    </SimpleForm>
  );
};

OperatorsForm.propTypes = {
  record: PropTypes.object,
  type: PropTypes.oneOf(['create', 'edit']),
};

OperatorsForm.defaultProps = {
  type: 'create',
};

export default OperatorsForm;
