import React from 'react';
import { Edit } from 'react-admin';
import * as renderers from '../../../../utils/renderers';
import Form from '../../../molecules/Cars/Form';

const Title = ({ record }) => <span>{renderers.Fullname(record)}</span>;

export default (props) => (
  <Edit {...props} title={<Title />} actions={null}>
    <Form type='edit' />
  </Edit>
);
