import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';

export default (props) => (
  <Filter {...props}>
    <ReferenceInput source='calendar' reference='calendars'>
      <SelectInput optionText='name' />
    </ReferenceInput>
    <ReferenceInput source='warehouse' reference='warehouses'>
      <SelectInput optionText='name' />
    </ReferenceInput>
  </Filter>
);
