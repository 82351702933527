import { PropTypes } from 'prop-types';
import React from 'react';
import { ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin';

const OperatorsForm = (props) => {
  const common = {
    validate: [required()],
    fullWidth: true,
  };

  return (
    <SimpleForm {...props}>
      <TextInput source='code' {...common} />
      <ReferenceInput perPage={999} source='warehouse' reference='warehouse_super_classes' {...common}>
        <SelectInput optionText='name' />
      </ReferenceInput>
      <ReferenceInput source='model' reference='battery_models' perPage={999} {...common}>
        <SelectInput source='name' />
      </ReferenceInput>
    </SimpleForm>
  );
};

OperatorsForm.propTypes = {
  record: PropTypes.object,
  type: PropTypes.oneOf(['create', 'edit']),
};

OperatorsForm.defaultProps = {
  type: 'create',
};

export default OperatorsForm;
