import * as React from 'react';
import { Show, SimpleShowLayout, ArrayField, Datagrid, FunctionField, withTranslate } from 'react-admin';
import moment from 'moment';
import ShowActions from './actions';

export default withTranslate(({ translate, ...props }) => (
  <Show {...props} actions={<ShowActions />}>
    <SimpleShowLayout>
      <ArrayField source='daysWithoutSchedule'>
        <Datagrid>
          <FunctionField
            label={translate('resources.calendars.fields.days')}
            render={(record) => moment(record).format('DD-MM-YYYY')}
          />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
));
