import PropTypes from 'prop-types';
import React from 'react';

const TelephoneField = ({ record = {}, source }) => <a href={`tel:${record[source]}`}>{record[source]}</a>;

TelephoneField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default TelephoneField;
