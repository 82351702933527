import axios from 'axios';
import jwtDecode from 'jwt-decode';
import get from 'lodash/get';
import { AUTH_PATH } from '../../config/constants';

export const login = data => axios.post(AUTH_PATH, data);

export const hasAdminPermissions = token => {
  let permissions = get(jwtDecode(token), 'roles', []);

  return permissions.includes('ROLE_ADMIN') ? permissions : false;
};
