import React from 'react';
import { Datagrid, EditButton, List, ReferenceField, TextField } from 'react-admin';
import FullnameField from '../../../atoms/FullnameField';
import Filters from './filters';


export default props => (
  <List {...props} filters={<Filters />} exporter={false}>
    <Datagrid>
      <FullnameField source='name' />
      <ReferenceField source='currentService' reference='services' allowEmpty>
        <TextField source="address" />
      </ReferenceField>
      <ReferenceField source='status' reference='operator_statuses' linkType={false} allowEmpty>
        <TextField source='name' />
      </ReferenceField>

      <EditButton />
    </Datagrid>
  </List>
);
