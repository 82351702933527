import axios from 'axios';
import {ENTRY_POINT, IMPORT_BATTERIES_PATH, TOKEN_IMPORTS_API} from '../../config/constants';

var bodyFormData = new FormData();
bodyFormData.append('token_import', TOKEN_IMPORTS_API);
export const importBatteries = () => axios.post(IMPORT_BATTERIES_PATH, bodyFormData);

//Get Resumen Qualify
export const importOpt1 = () => axios.get(ENTRY_POINT+'/qualify/opt1');
export const importOpt2 = () => axios.get(ENTRY_POINT+'/qualify/opt2');
export const importOpt3 = () => axios.get(ENTRY_POINT+'/qualify/opt3');
export const importOpt4 = () => axios.get(ENTRY_POINT+'/qualify/opt4');
