import spanishMessages from 'aor-language-spanish';

spanishMessages.ra.page.list = '%{name}';

export default {
  ...spanishMessages,
  accept: 'Aceptar',
  loading: 'Cargando',
  fields: 'Campos',
  resources: {
    unregistered_clients: {
      name: 'Clientes',
      fields: {
        name: 'Nombre',
        surname: 'Apellidos',
        email: 'Correo electónico',
        phone: 'Teléfono',
        password: 'Contraseña',
        active: 'Activo',
      },
    },
    operators: {
      name: 'Mecánicos',
      fields: {
        id: 'Nº Servicio',
        name: 'Nombre',
        surname: 'Apellidos',
        email: 'Correo electónico',
        phone: 'Teléfono',
        password: 'Contraseña',
        status: 'Estado',
        currentService: 'Servicio en curso',
        geolocation: 'Geolocalización',
        warehouse: 'Almacén',
        car: 'Coche',
      },
    },
    services: {
      name: 'Servicios',
      fields: {
        id: 'Nº Servicio',
        client: 'Cliente',
        operator: 'Mecánico',
        date: 'Fecha',
        dateDate: 'Fecha',
        dateTime: 'Hora',
        status: 'Estado',
        address: 'Dirección',
        carModel: 'Vehículo',
        enrollment: 'Matrícula',
        replacement: 'Inventario',
        replacementModel: 'Modelo detectado',
        comments: 'Comentarios',
        files: 'Archivos',
        isNeedChangeBattery: '¿Necesita cambiar batería?',
        isChangedBattery: '¿Batería cambiada?',
        reason: 'Motivo',
        explainReason: 'Explicación',
        origin: 'Origen',
        manual: 'Manual',
        landing: 'Landing',
        web: 'Web',
        hasResidualDelivery: 'Entrega de residuo',
        weightResidue: 'Peso del residuo',
      },
    },
    battery_models: {
      name: 'Modelos',
      fields: {
        name: 'Nombre',
        code: 'Referencia Fabricante',
        price: 'Precio (en Euros)',
        capacity: 'Capacidad',
        cca: 'CCA',
        description: 'Descripción',
        stock: 'Stock',
      },
    },
    batteries: {
      name: 'Inventario',
      fields: {
        code: 'Código de Barras',
        warehouse: 'Almacén',
        model: 'Modelo',
      },
    },
    media_objects: {
      fields: {
        contentUrl: 'Enlace',
      },
    },
    stock_logs: {
      fields: {
        date: 'Fecha',
        log: 'Stock',
        userAction: 'Mecánico',
      },
    },
    warehouses: {
      name: 'Almacenes',
      fields: {
        name: 'Nombre',
        direction: 'Dirección',
        locationRef: 'Referencia de localización',
        operators: 'Mecánicos',
        calendar: 'Calendario',
        codeBcId: 'Código de BC'
      },
    },
    calendars: {
      name: 'Calendarios',
      fields: {
        name: 'Nombre',
        warehouse: 'Almacén',
        days: 'Días',
        daysWithoutSchedule: 'Días sin horario',
      },
    },
    schedules: {
      name: 'Horarios',
      fields: {
        date: 'Día',
        timeFrom: 'Abierto desde',
        timeTo: 'Abierto hasta',
        operators: 'Mecánicos',
        calendar: 'Calendario',
        warehouse: 'Almacén',
        warehouses: 'Almacenes',
      },
    },
    cars: {
      name: 'Vehículos',
      fields: {
        name: 'Nombre',
        enrollment: 'Matrícula',
      },
    },
  },
};
