import create from './Create';
import edit from './Edit';
import list from './List';
import show from './Show';

export default {
  create,
  edit,
  list,
  show,
};
